.oemi-generic-dialog {
  .message-body {
    padding-left: 16px;
    padding-right: 16px;

    .message-layout-grid {
      padding: 8px 16px 16px 16px;
    }
  }

  .message-footer {
    padding-top: 0px;
  }

  word-break: break-word;
}
