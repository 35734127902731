@import "../../style/colors";

.oemi-warranty-response {
  &_wrapper {
    h5 {
      color: @cdk-color-response-text;
      font-family: Roboto Mono, monospace !important;
      font-size: 14px;
      letter-spacing: 0.09px;
      line-height: 16px;
      white-space: pre;
    }
    padding: 16px;
  }
}
