.card__add-oem-remote-id {
  margin-top: 0px;
  position: absolute;
  height: 123px;
  left: 100px;
  padding: 0px 10px;
  z-index: 100;

  add-oem-remote-id-button {
    float: right;
  }
}
