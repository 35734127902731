.media-content-wrapper {
  padding-top: 10px;
  padding-bottom: 10px;
}

.viewer-content-grid {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: grid;
  grid-template-columns: 100% 0%;
}

#viewer-content-panel {
  width: 75%;
  overflow-y: auto;
  float: left;
  background-color: #f5f5f5;
  box-shadow: inset -3px 0 6px -6px black;
}

#viewer-right-panel {
  background-color: #ffffff;
}

#viewer-right-panel .viewer-error-msg {
  padding: 0px 0px 0px 24px;
}

#viewer-right-panel .error-msg {
  color: #d0021b;
  overflow-wrap: break-word;
}

#viewer-right-panel-content {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  right: 0%;
}

#viewer-right-panel-content .cdk-expansion-panel-group {
  max-width: 99%;
}

.viewer-panel-button {
  height: 36px;
  width: 126px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

body {
  margin: 0;
}

#viewer-right-panel-content .mdc-tab {
  min-width: 0;
}

#viewer-right-panel-content .viewer-error-msg {
  color: #d0021b;
}
#viewer-right-panel-content .mdc-tab-bar {
  width: 100%;
}

#viewer-print-box .mdc-layout-grid {
  padding: 0px;
}

#viewer-print-box {
  min-height: 420px;
}

#viewer-container {
  height: inherit;
}

#viewer-container .signingContainer {
  width: 100%;
}

.viewer-document-outer-container {
  margin-left: 47%;
}

.viewer-document-div-zoom {
  position: fixed;
  z-index: 1;
  bottom: 0.5rem;
}

.viewer-document-button-zoom {
  height: 40px;
  width: 40px;
  color: rgb(175, 175, 175);
  background-color: black;
  border: 2px solid #0d030a;
  display: inline-block;
  vertical-align: middle;
  padding-left: 7px;
}

#viewer-document-zoom-out-button {
  border-radius: 6px 0 0 6px;
}

#viewer-document-zoom-in-button {
  border-radius: 0 6px 6px 0;
}

.file-drop {
  /* relatively position the container bc the contents are absolute */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  font-family: "Roboto", sans-serif;
}

.file-drop.Highlight {
  box-shadow: inset 0 0 13px 3px #82c600;
}

input[type="file"] {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 1px;
  height: 1px;
}

input[type="file"] + label {
  background: white;
  color: #0074d9;
  width: 126px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 4px 0 rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  margin-top: 4px;
  padding: 4px;
}

input[type="file"] + label:hover {
  background: #d0d0ce;
}

#excel-viewer-container body {
  margin: 0;
  background-color: #f5f5f5;
}

#excel-viewer-container table {
  border-width: 1px 0px 0px 1px;
  font-size: 10pt;
  font-family: arial;
  border-spacing: 0px;
  border-collapse: collapse;
}

#excel-viewer-container tbody {
  border: 1px solid #716f64;
}

#excel-viewer-container table tr {
  background-color: #efedde;
  border: 1px solid #aca899;
  border-width: 0px 1px 1px 0px;
  font-weight: normal;
}

#excel-viewer-container table td {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #c0c0c0;
  border-width: 0px 1px 1px 0px;
}

#excel-viewer-container table td.heading {
  background-color: #efedde;
  text-align: center;
  padding: 0px 0px 0px 0px;
  border: 1px solid #aca899;
  border-width: 0 1px 1px 0;
}

#excel-viewer-container {
  display: flex;
  height: 100%;
}

#excel-viewer-container .cdk-mobile-stepper {
  height: 100%;
  width: 75%;
  margin: auto;
}

#excel-viewer-container .cdk-mobile-stepper__body {
  overflow: auto;
  height: inherit;
  width: 100%;
}

#excel-viewer-container .cdk-mobile-stepper__footer {
  border: 1px solid #c0c0c0;
  bottom: 0px;
}

#excel-viewer-container .cdk-mobile-stepper__footer .mdc-select {
  height: 36px;
}

#excel-viewer-container
  .cdk-mobile-stepper__footer
  .mdc-select__native-control {
  padding-top: 0;
}

#excel-viewer-container {
  height: 100vh;
}

#excel-viewer-container .excelContainer {
  height: calc(100% - 70px);
}

#excel-viewer-container table {
  display: block;
  overflow-y: auto;
  height: 100%;
}

.fullScreenDialog__MultiViewer .mdc-dialog__body--cdk-full-height {
  overflow-y: initial;
  height: calc(100vh - 80px);
}

.fullScreenDialog__MultiViewer .full-screen-body {
  height: inherit;
}

.fullScreenDialog__MultiViewer .viewer-content-grid {
  height: inherit;
}

.fullScreenDialog__MultiViewer #excel-viewer-container {
  height: calc(100vh - 80px);
}

.fullScreenDialog__MultiViewer #excel-viewer-container .excelContainer {
  height: calc(100vh - 80px);
}

.fullScreenDialog__MultiViewer #excel-viewer-container table {
  height: calc(100% - 64px);
}

.fullScreenDialog__MultiViewer
  #excel-viewer-container
  .cdk-mobile-stepper__body {
  padding: 0;
}

.viewer-content-panel {
  height: inherit;
}

#excel-viewer-container .cdk-mobile-stepper__body {
  padding: 0;
}

.mdc-dialog #excel-viewer-container {
  height: 100%;
}

.mdc-dialog .viewer-content-grid {
  height: calc(100vh - 100px);
}

#html-viewer-container {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;
  padding: 5px 10px 5px 10px;
  margin: auto;
  margin-bottom: 10px;
  font-family: "Roboto", serif;
  max-width: 792px;
  height: fit-content;
}

/*Need to Scope this to  to just the FillPanel*/
.react-pdf__Document {
  display: grid;
  justify-content: center;
}

/* TODO: create space between pages.*/
/* Creates space between pages but the annotations are to high now. */
/*.react-pdf__Page{*/
/*  !*padding-top: 10px;*!*/
/*  !*padding-bottom: 10px;*!*/
/*}*/
