.oem-files-menu-page {
  .drive-flex-page-section {
    padding: 24px !important;
  }

  .oem-files-menu-sub {
    padding: 24px;
    height: 36vh;
    display: flex;
    flex-direction: column;

    .oem-files-menu-sub-pagetitle {
      font-weight: 500;
      font-size: 20px;
      padding: 0 8px 8px 8px;
      text-transform: capitalize;
    }
  }

  .oem-files-menu-button {
    width: 250px;
    justify-content: flex-start;
    padding: 0 8px 0px 8px;
    text-transform: none;
  }

  .oem-menu-no-data {
    padding: 20px;
  }
}

.oem-files-page {
  .mdc-dialog__body {
    height: 100vh;
  }
  .full-screen-body {
    height: 86vh;
  }
  .mdc-dialog__surface {
    overflow: auto;
  }

  .mdc-typography--no-margins {
    padding-bottom: 12px;
  }

  .menu_bar {
    .mdc-tab-bar-scroller__indicator--back {
      display: none;
    }
    .mdc-tab--active .mdc-tab__indicator {
      visibility: visible;
    }
  }

  .backbutton_alignment {
    color: #0e79cc;
    text-transform: capitalize;
    padding-left: 0px;
  }

  .oem-response-filter-date-range {
    display: flex;
    padding-right: 24px;
  }

  .page-section {
    height: 65vh;
  }

  .page-section .mdc-card {
    height: 100%;
    overflow: scroll;
  }

  .drive-flex-page-section {
    padding-left: 24px;
    padding-top: 24px !important;
    padding-bottom: 24px;

    .menu-button {
      width: 300px;
      justify-content: flex-start;
      text-transform: capitalize;
    }
  }

  .section-body {
    .drive-flex-page-section {
      margin-top: 0%;
      height: 88vh;
    }
    .mdc-card {
      height: 100%;
      padding: 24px;
      margin-bottom: 24px;
    }
  }

  .oem-response-filter {
    padding: 5px 15px 5px 15px;
    border-bottom: 1px solid #e3e3e3;
    min-height: 94px;
    overflow: visible;
    height: auto;
    width: 100%;

    .dateSeparator {
      padding: 36px 20px 0px 20px;
      margin: 0px;
    }

    .search-text {
      padding-top: 20px;
    }
    .fromDate {
      padding-top: 12px;
    }
    .servicereport-type {
      padding-top: 4px;
    }
    .partsreport-type,
    .vehiclereport-type,
    .otherreport-type,
    .report-type {
      padding-top: 12px;
    }

    .applyBtn {
      padding-top: 25px;
      text-align: center;
    }
  }

  .header-section {
    .align-filter {
      padding-top: 8px;
    }
  }

  .drive-flex .menu-layout__content {
    background-color: #f5f5f5;
  }

  .drive-flex-page-content {
    padding-bottom: 0px;
  }

  .no-records-text {
    text-align: center;
  }

  .mdc-list-item {
    display: flex;
    justify-content: flex-start;
    height: 100%;
    padding: 0 16px;
  }

  .mdc-list-item__text,
  .mdc-list-item__secondary-text {
    white-space: pre-wrap;
    overflow: hidden;
    display: block;
  }

  .oem-responses-loading {
    align-self: center;
    justify-self: center;
  }

  .oem-responses-filesinfo {
    display: flex;
    align-items: flex-start;
    height: 77vh;
  }

  .oem-responses-fileslistcontainer {
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: 400px;
    width: 358px;
    height: 77vh;
    padding-top: 0px;
    padding-bottom: 0px;

    .mdc-typography--no-margins {
      padding-bottom: 0px;
    }
  }

  .oem-responses-filecontent {
    font-family: "Roboto Mono", monospace !important;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 16px;
    white-space: pre;
  }

  .oem-responses-filesdatacontainer.oem-responses-file {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-x: scroll;
    min-height: 400px;
    height: 77vh;
    .responsebody-layout-div {
      width: inherit;
    }
    .responsebody-layout-grid {
      padding: 0px;
      margin-left: 2px;
      margin-right: 0px;
      width: 100%;
      display: grid;
      justify-content: center;
    }
    .print-btn {
      float: right;
      margin-right: 8px;
      margin-top: 5px;
    }
  }

  .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
    height: 52px;
  }

  .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
    margin-top: 8px;
  }
}

.oem-files-page.oem-files-page_comm {
  .oem-responses-filesdatacontainer.oem-responses-file {
    display: block;
  }
}

.oem-files-page_full-screen {
  .mdc-elevation--z4 {
  }

  .drive-flex-page-section {
    padding: 0px;
  }

  .mdc-card {
    box-shadow: none;
  }
}
