.consumer-privacy-section {
  @icon-width: 20px;

  .customer-form-section-title {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    margin-bottom: 15px;
  }
  .privacy-info-text,
  .portal-section .mdc-button {
    display: flex;
  }
  .privacy-info-text {
    @font-size: 13px;

    font-size: @font-size;
    margin-bottom: 12px;
    .cdkicon-info {
      color: rgba(0, 0, 0, 0.6);
      margin-right: 11px;
      width: @icon-width;
    }
    span {
      padding-top: calc(@icon-width / 2 - @font-size / 2);
    }
  }
  .portal-section {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    .mdc-button {
      margin-bottom: 11px;
    }
  }
}
