.rp-report-multi-select {
  /*
  DNARP-990: Chrome only bug where multi-select gets a black background. Override the black CSS to transparent.
   */ }
  .rp-report-multi-select .multi-select-container {
    display: flex !important; }
  .rp-report-multi-select .multi-select-selection-display {
    flex: 1;
    min-width: 0;
    min-height: 27px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .rp-report-multi-select .multi-select-icon {
    align-self: flex-end; }
  .rp-report-multi-select .multi-select-text {
    width: 100%; }
  .rp-report-multi-select .show-bottom-border {
    border-bottom: solid 1px #e4e4e4; }
  .rp-report-multi-select .mdc-menu--open .mdc-text-field-helper-text {
    display: none; }
  .rp-report-multi-select .button-group {
    text-align: right; }
  .rp-report-multi-select :not(.mdc-list--non-interactive) > .mdc-list-item::before,
  .rp-report-multi-select :not(.mdc-list--non-interactive) > .mdc-list-item::after {
    will-change: transform !important; }
